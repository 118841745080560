.titleMain {
  height: 80px;
}

.titleInner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: rgb(0, 255, 51);
  background-color: rgb(26, 26, 26);
  height: 45px;
}

#titleText {
  font-size: 20px;
}

.buttonsWide {
  display: none;
}

.smallOnly {
  height: 45px;
  background-color: rgb(26, 26, 26);
  display: flex;
  justify-content: space-around;
}

@media only screen and (min-width: 706px) {
  .titleMain {
    height: 35px;
  }

  .smallOnly {
    display: none;
  }

  .buttonsWide {
    display: flex;
  }

  #righty {
    position: absolute;
    right: 2%;
  }

  #lessrighty {
    position: absolute;
    right: 8%;
  }

  #lefty {
    position: absolute;
    left: 2%;
  }

  @media only screen and (max-width: 1224px) {
    #lessrighty {
      right: 15%;
    }
  }
}
